var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": "",
      "variant": "info"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DownloadIcon"
    }
  }), _vm._v(" Download Surat Jalan ")], 1)], 1), _c('h3', {
    staticClass: "text-center mb-2"
  }, [_vm._v("Surat Jalan Ajuan Barang")]), _c('ul', {
    staticClass: "mb-2"
  }, [_c('li', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Salesman:")])]), _c('li', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Wilayah:")])]), _c('li', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Tanggal Pengambilan:")])]), _c('li', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Tanggal Pengembalian:")])])]), _c('table', {
    staticClass: "table responsive table-responsive table-bordered table-striped w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center valign-center",
    staticStyle: {
      "width": "3%"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticStyle: {
      "vertical-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("BRAND")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Pengambilan ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Return ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Pengembalian ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Terjual ")])]), _c('tr', [_c('th', [_vm._v("Dos")]), _c('th', [_vm._v("Bal")]), _c('th', [_vm._v("Slop")]), _c('th', [_vm._v("Dos")]), _c('th', [_vm._v("Bal")]), _c('th', [_vm._v("Slop")]), _c('th', [_vm._v("Dos")]), _c('th', [_vm._v("Bal")]), _c('th', [_vm._v("Slop")]), _c('th', [_vm._v("Dos")]), _c('th', [_vm._v("Bal")]), _c('th', [_vm._v("Slop")])])]), _c('tbody', _vm._l([0, 102, 2, 3, 4, 1], function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_c('strong', [_vm._v("GA BOLD20")])]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")]), _c('td', [_vm._v("0")])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }